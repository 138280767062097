import React, { useEffect } from 'react';
import { isEmpty, get } from 'lodash';
import qs from 'query-string';
import session, { SESSION_PROVIDER } from 'utils/Session';
import { useHistory } from 'react-router-dom';
import { useRequest } from 'utils/Request';

import HttpStatus from 'common/HttpStatus';
import PageSpinner from 'common/PageSpinner';
import LoadSocialProvider from 'Services/Auth/LoadSocialProvider';

const baseAuthRedirect = (providerUrl) => {
  const fullRedirectUrl = `${window.location.protocol}//${window.location.host}/oauth/redirect`;
  const oauthGrantUrl = `${providerUrl}${fullRedirectUrl}`;
  return oauthGrantUrl;
};

function OAuthPermission() {
  const history = useHistory();
  const fetchProvider = useRequest(LoadSocialProvider, {
    onSuccess: ({ data }) => {
      const oauthGrantUrl = baseAuthRedirect(data.url);
      session.setProvider(data, oauthGrantUrl);
      setTimeout(() => {
        window.location.href = oauthGrantUrl;
      }, 500);
    },
  });

  useEffect(() => {
    const providerSession = session.getDomainCookie(SESSION_PROVIDER);
    const cachedProviderUrl = get(providerSession, 'url');
    const params = qs.parse(window.location.search);

    const destPage = params.dest;

    session.setProviderGateway(destPage);

    if (!cachedProviderUrl) {
      fetchProvider.start(params.provider);
      return;
    }

    const username = session.getUsername();
    if (username) {
      history.push(`/${destPage}`);
      return;
    }

    if (cachedProviderUrl) {
      const oauthGrantUrl = baseAuthRedirect(cachedProviderUrl);
      window.location.href = oauthGrantUrl;
    }
  }, []);

  return (
    <div>
      {fetchProvider.inProcess && <PageSpinner />}
      {!isEmpty(fetchProvider.error) && (
        <HttpStatus status="401" title="Could not authenticate" />
      )}
    </div>
  );
}

export default OAuthPermission;
