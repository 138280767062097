import React, { useEffect } from 'react';
import { Card } from 'react-bootstrap';
import Button from 'common/Buttons/Button';
import { func, string } from 'prop-types';
import { get } from 'lodash';

import withServices from 'utils/withServices';
import useToViewModel from 'utils/useToViewModel';
import { useRequest } from 'utils/Request';
import PluginDetails from 'Services/Plugins/Details';
import Placeholder from 'common/Animations/Placeholder';
import PlaceholderProvider from 'common/Animations/Placeholder/Provider';
import PluginDetailsVM from './PluginDetailsVM';
import placeholders from './placeholders';
import './styles.scss';

function PluginDetail({ onGoBack, pluginId, fetchService }) {
  const fetch = useRequest(fetchService);
  const vm = useToViewModel(get(fetch, 'response.data'), PluginDetailsVM);

  const handleRemoveClick = () => {};

  useEffect(() => {
    fetch.start(pluginId);
  }, [pluginId]);

  return (
    <PlaceholderProvider
      loading={(fetch.inProcess || !fetch.response) && !fetch.error}
    >
      <div id="pluginDetail" className="plugin-details">
        <Placeholder placeholderProps={placeholders.image}>
          <div className="detail-plugin-image-card">
            <Card.Img
              variant="top"
              src={vm.logoUrl}
              alt="Icon"
              className="detail-plugin-image-card"
            />
          </div>
        </Placeholder>
        <div>
          <Placeholder
            placeholderProps={placeholders.title}
            className="cx-content-title mt-1"
            active={fetch.inProcess}
          >
            {vm.name}
          </Placeholder>
          <Placeholder
            placeholderProps={placeholders.description}
            className="cx-content my-2"
          >
            <strong>About:</strong> {vm.description}
          </Placeholder>
          <Placeholder
            placeholderProps={placeholders.author}
            className="cx-content-sub mb-1"
          >
            <strong>By</strong> {vm.developerName}
          </Placeholder>
          <Placeholder
            placeholderProps={placeholders.lastUpdate}
            className="cx-content-sub mb-1"
          >
            <strong>Terms:</strong> {vm.termsUrl}
          </Placeholder>
          <div className="d-flex my-2 justify-content-between">
            <Button
              onClick={onGoBack}
              variant="secondary"
              className="panel-action panel-form-button p-1 mr-2  d-flex justify-content-center"
            >
              <Placeholder placeholderProps={placeholders.button}>
                Go Back
              </Placeholder>
            </Button>
            <div className="d-flex">
              <Button
                onClick={handleRemoveClick}
                variant="secondary"
                className="disabled panel-action panel-form-button p-1 d-flex justify-content-center"
              >
                <Placeholder placeholderProps={placeholders.removeButton}>
                  Remove
                </Placeholder>
              </Button>
            </div>
          </div>
        </div>
      </div>
    </PlaceholderProvider>
  );
}

PluginDetail.propTypes = {
  fetchService: func.isRequired,
  onGoBack: func.isRequired,
  pluginId: string.isRequired,
};

export default withServices({ fetchService: PluginDetails }, PluginDetail);
