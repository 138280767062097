import React from 'react';
import { CardDeck, Col, Row } from 'react-bootstrap';
import { func } from 'prop-types';
import { get } from 'lodash';

import SearchMyPlugins from 'Services/Me/SearchPlugins';
import FadeIn from 'common/Animations/FadeIn';
import SearchInput from 'common/Inputs/Search';
import DataSet from 'common/DataSet';
import NoResults from 'common/Messages/NoResults';
import LoadingSpinner from 'common/LoadingSpinner';
import useSearchState from 'utils/useSearchState';
import withServices from 'utils/withServices';

import PluginSelectCard from './components/PluginSelectCard';

function PluginSelect({ searchService }) {
  const search = useSearchState(
    (qs, handler) => {
      searchService('userId', qs, handler);
    },
    ['q'],
    undefined,
    {
      sort: [{ field: 'name', order: 'asc' }],
      limit: 12,
    },
  );

  const showNoResults =
    !search.query.result.total && !search.pristine && !search.query.isLoading;

  return (
    <div className="px-4">
      <div className="header d-flex align-items-center justify-content-between">
        <SearchInput
          placeholder="Search My Plugins"
          name="myPluginSearch"
          onChange={get(search, 'change')}
          value={get(search, 'query.qs')}
        />
      </div>
      <DataSet
        onPageChange={get(search, 'changePage')}
        onReload={get(search, 'reload')}
        total={get(search, 'query.result.total')}
        page={get(search, 'query.page')}
        limit={get(search, 'query.limit')}
        hide="limit"
        isFetching={get(search, 'query.isLoading')}
        hasError={false && get(search, 'query.error')}
      >
        {showNoResults && (
          <div className="mt-2">
            <NoResults />
          </div>
        )}
        {get(search, 'query.isLoading') ? (
          <LoadingSpinner />
        ) : (
          <CardDeck className="mt-2">
            <Row>
              {get(search, 'query.result.data').map((value) => (
                <Col
                  key={value.id}
                  className="p-0 py-2"
                  xs="6"
                  sm="6"
                  md="4"
                  lg="4"
                >
                  <FadeIn delay={value}>
                    <PluginSelectCard plugin={value} />
                  </FadeIn>
                </Col>
              ))}
            </Row>
          </CardDeck>
        )}
      </DataSet>
    </div>
  );
}

PluginSelect.propTypes = {
  searchService: func.isRequired,
};

export default withServices({ searchService: SearchMyPlugins }, PluginSelect);
