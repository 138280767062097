import React, { useMemo } from 'react';
import { node, number, bool, func, oneOf } from 'prop-types';
import Form from 'react-bootstrap/Form';
import Pagination from 'react-bootstrap/Pagination';
import RetryableErrorMessage from 'common/Messages/RetryableError';

const getRange = (page, limit, total) => {
  if (!total) return '';
  const higherBoundary = page * limit;
  const lowerBoundary = higherBoundary - limit + 1;
  return `${lowerBoundary}-${
    higherBoundary <= total ? higherBoundary : total
  } of ${total}`;
};

function DataSet({
  isFetching,
  hasError,
  hide,
  menuPosition,
  children,
  total,
  page,
  limit,
  onLimitChange,
  onPageChange,
  onReload,
  onSortChange,
}) {
  const lastPage = useMemo(() => {
    return Math.ceil(total / limit);
  }, [limit, total]);

  const disableControls = isFetching || hasError;

  // eslint-disable-next-line no-unused-vars
  const handleSortByChange = (_, value) => {
    onSortChange(value);
  };

  const handleLimitChange = (element) => {
    onLimitChange(element.target.value);
  };

  const handleFirstPage = () => {
    onPageChange(1);
  };

  const handleNextPage = () => {
    const expectedNext = page + 1;
    const newPage = expectedNext <= lastPage ? expectedNext : lastPage;
    onPageChange(newPage);
  };

  const handlePreviousPage = () => {
    const expectedPrev = page - 1;
    const newPage = expectedPrev > 0 ? expectedPrev : 1;
    onPageChange(newPage);
  };

  const handleLastPage = () => {
    onPageChange(lastPage);
  };

  const menu = (
    <div className="d-flex align-items-center justify-content-end">
      {hide !== 'limit' && (
        <div className="ml-3">
          <Form.Control
            disabled={disableControls}
            className="pl-1"
            as="select"
            value={limit}
            onChange={handleLimitChange}
          >
            <option value="10">Show: 10</option>
            <option value="25">Show: 25</option>
            <option value="50">Show: 50</option>
          </Form.Control>
        </div>
      )}
      <div className="ml-3">{getRange(page, limit, total)}</div>
      <div className="ml-3">
        <Pagination className="mb-0">
          <Pagination.First
            disabled={disableControls}
            onClick={handleFirstPage}
          />
          <Pagination.Prev
            disabled={disableControls}
            onClick={handlePreviousPage}
          />
          <Pagination.Next
            disabled={disableControls}
            onClick={handleNextPage}
          />
          <Pagination.Last
            disabled={disableControls}
            onClick={handleLastPage}
          />
        </Pagination>
      </div>
    </div>
  );

  return (
    <div className="data-table mt-3">
      {menuPosition === 'top' && menu}
      {!isFetching && hasError ? (
        <RetryableErrorMessage onReload={onReload} />
      ) : (
        children
      )}
      {menuPosition === 'bottom' && menu}
    </div>
  );
}

DataSet.propTypes = {
  children: node.isRequired,
  hasError: bool,
  hide: oneOf(['limit']),
  isFetching: bool.isRequired,
  limit: number.isRequired,
  menuPosition: oneOf(['top', 'bottom']),
  onLimitChange: func.isRequired,
  onPageChange: func.isRequired,
  onReload: func.isRequired,
  onSortChange: func.isRequired,
  page: number.isRequired,
  total: number.isRequired,
};

DataSet.defaultProps = {
  hasError: false,
  hide: null,
  menuPosition: 'top',
};

export default DataSet;
