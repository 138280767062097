import React from 'react';
import { string } from 'prop-types';
import Spinner from 'react-bootstrap/Spinner';

function LoadingSpinner({ message }) {
  return (
    <div className="loader d-flex justify-content-center align-items-center">
      <div className="d-flex flex-column align-items-center">
        <Spinner className="text-accent" animation="border" />
        <div className="tmp-loader font-weight-normal text-primary mb-2">
          {message}
        </div>
      </div>
    </div>
  );
}

LoadingSpinner.propTypes = {
  message: string.isRequired,
};

LoadingSpinner.defaultProps = {};

export default LoadingSpinner;
