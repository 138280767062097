import React from 'react';
import cx from 'classnames';
import { func, string } from 'prop-types';
import { ListGroup } from 'react-bootstrap';

import useToViewModel from 'utils/useToViewModel';

import Button from 'common/Buttons/Button';
import Placeholder from 'common/Animations/Placeholder';
import PluginVM from './PluginItemVM';
import placeholders from './placeholders';

function PluginItem({ className, plugin, onGoToDetails, onInstall }) {
  const vm = useToViewModel(plugin, PluginVM);
  const handleContentClick = () => {
    onGoToDetails(vm.id);
  };

  return (
    <ListGroup.Item
      className="my-1 border-bottom rounded-top cursor-pointer"
      onClick={handleContentClick}
    >
      <div className={cx(className)}>
        <Placeholder
          placeholderProps={placeholders.title}
          className="cx-content-title"
        >
          {vm.name}
        </Placeholder>
        <Placeholder
          placeholderProps={placeholders.description}
          className="cx-content my-2"
        >
          {vm.description}
        </Placeholder>
        <div className="d-flex justify-content-between align-items-center mt-2">
          <Placeholder
            placeholderProps={placeholders.lastUpdate}
            className="cx-content-sub"
          >
            By {vm.developerName}
          </Placeholder>
          <Button
            className="cx-content-action"
            size="sm"
            value={vm.id}
            onClick={onInstall}
          >
            <Placeholder placeholderProps={placeholders.button}>
              Install
            </Placeholder>
          </Button>
        </div>
      </div>
    </ListGroup.Item>
  );
}

PluginItem.propTypes = {
  className: string,
  onGoToDetails: func.isRequired,
  onInstall: func.isRequired,
  plugin: PluginVM.propTypes.isRequired,
};

PluginItem.defaultProps = {
  className: undefined,
};

export default PluginItem;
