import React from 'react';
import { Formik } from 'formik';
import { func } from 'prop-types';
import * as yup from 'yup';
import Form from 'react-bootstrap/Form';
import { get } from 'lodash';

import withServices from 'utils/withServices';
import { useRequest } from 'utils/Request';
import UploadManifest from 'Services/Manifests/Upload';
import SubmitErrorMessage from 'common/Messages/ErrorMessage';
import ProcessingButton from 'common/Buttons/ProcessingButton';
import Button from 'common/Buttons/Button';
import FileUpload from 'common/Inputs/FileUpload';

import Texts from './texts';
import './styles.scss';

const validationSchema = yup.object({
  file: yup.object().required(Texts.requiredError(Texts.fileLabel)),
});

export function ManifestUpload({ upload, onSubmitted, onCancel }) {
  const postPlugin = useRequest(upload, {
    onSuccess: (response) => {
      onSubmitted(response.data.id);
    },
    onError: () => {},
  });

  const handleFormSubmit = (form, formikBag) => {
    postPlugin.start(form.file, formikBag);
  };

  return (
    <div>
      <p className="cx-content mb-1">{Texts.guideMessage}</p>
      <Formik
        initialValues={{ file: '' }}
        validationSchema={validationSchema}
        onSubmit={handleFormSubmit}
      >
        {({
          handleSubmit,
          setFieldValue,
          submitCount,
          errors,
          isSubmitting,
          values,
        }) => {
          return (
            <Form noValidate>
              <Form.Group>
                <Form.File id="manifest-upload-file">
                  <Form.File.Label className="cx-label">
                    Manifest File:
                  </Form.File.Label>
                  <FileUpload
                    name="file"
                    accept=".json"
                    className="cx-input"
                    value={values.file}
                    onChange={setFieldValue}
                  />
                </Form.File>
                {!!submitCount && errors.file && (
                  <div className="text-danger mt-1 cx-input">{errors.file}</div>
                )}
                {!isSubmitting && get(errors, 'manifestValidations') && (
                  <div className="text-danger mt-2 cx-input p-1 rounded manifest-validations-box">
                    {get(errors, 'manifestValidations', []).map((e) => (
                      <div
                        key={`${e.field}_${e.message}`}
                      >{`* ${e.field}: ${e.message}`}</div>
                    ))}
                  </div>
                )}
              </Form.Group>
              <div className="d-flex justify-content-end">
                <Button
                  size="md"
                  variant="secondary"
                  onClick={onCancel}
                  className="mr-2 panel-form-button"
                >
                  {Texts.cancelLabel}
                </Button>
                <ProcessingButton
                  isProcessing={isSubmitting}
                  variant="primary"
                  type="submit"
                  size="md"
                  className="panel-form-button"
                  processLabel={Texts.submittingLabel}
                  onClick={handleSubmit}
                >
                  {Texts.submitLabel}
                </ProcessingButton>
              </div>
              {!isSubmitting && errors.invalidManifest && (
                <SubmitErrorMessage>
                  {errors.invalidManifest}
                </SubmitErrorMessage>
              )}
            </Form>
          );
        }}
      </Formik>
    </div>
  );
}

ManifestUpload.propTypes = {
  onCancel: func.isRequired,
  onSubmitted: func.isRequired,
  upload: func.isRequired,
};

export default withServices(
  {
    upload: UploadManifest,
  },
  ManifestUpload,
);
