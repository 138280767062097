import http from 'utils/Http';
import { get } from 'lodash';

async function UploadManifest(manifest, responseHandlers, formMutator) {
  try {
    const { data: response } = await http.post({
      url: 'api/v1/plugins',
      body: manifest,
    });

    const mapped = response;
    responseHandlers.onSuccess({
      data: mapped,
    });
  } catch (error) {
    if (
      get(error, 'response.status') === 422 &&
      get(error, 'response.data.errors')
    ) {
      formMutator.setErrors({
        manifestValidations: get(error, 'response.data.errors', []),
      });
    }
  } finally {
    formMutator.setSubmitting(false);
  }
}

export default UploadManifest;
