/* eslint-disable react/require-default-props */
import React from 'react';
import { any, func, string } from 'prop-types';
import Form from 'react-bootstrap/Form';

function FileUpload({ onChange, value, name, ...props }) {
  const handleChange = (event) => {
    const file = event.target.files[0];

    if (!file) {
      onChange(name, undefined);
      return;
    }

    const reader = new FileReader();
    // eslint-disable-next-line func-names
    reader.onload = function (e) {
      const data = e.target.result;
      onChange(name, JSON.parse(data));
    };
    // eslint-disable-next-line func-names
    reader.onerror = function () {
      onChange(name, undefined);
    };
    reader.readAsText(file);
  };

  return <Form.File.Input {...props} onChange={handleChange} />;
}

FileUpload.propTypes = {
  name: string.isRequired,
  onChange: func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  value: any,
};

export default FileUpload;
