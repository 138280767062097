import { useState, useCallback, useEffect } from 'react';
import { isEqual } from 'lodash';
import transformToQueryString from './transformToQueryString';

const DEFAULT_RESULT = {
  data: [],
  limit: null,
  lastPage: null,
  page: null,
  perPage: null,
  total: null,
};

const DEFAULT_QUERY_OPTIONS = {
  page: 1,
  limit: 10,
  sort: undefined,
};

function useSearchState(
  searchService,
  searchFieldsParam, // array
  filterFieldsParam, // array
  initialOptions = {},
) {
  const [pristine, setPristine] = useState(true);
  const [result, setResult] = useState(DEFAULT_RESULT);
  const [isLoading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [options, setOptions] = useState({
    ...DEFAULT_QUERY_OPTIONS,
    ...initialOptions,
  });
  const [query, setQuery] = useState(undefined);
  const [searchFields] = useState(searchFieldsParam);
  const [filterFields, setFilter] = useState(filterFieldsParam);

  const makeSearch = useCallback(() => {
    const qs = transformToQueryString(
      query,
      searchFields,
      filterFields,
      options,
    );
    setResult(DEFAULT_RESULT);
    setLoading(true);

    if (pristine) {
      setPristine(false);
    }

    return searchService(
      qs,
      {
        onSuccess: (queryResult) => {
          setLoading(false);
          setError(undefined);
          setResult({
            ...queryResult,
            total: parseInt(queryResult.total, 10),
          });
        },
        onError: (resultError) => {
          setLoading(false);
          setError(resultError);
        },
      },
      500,
    );
    // ignoring service as dep since it could cause issues
    // since depends on client's definition
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query, searchFields, filterFields, options]);

  useEffect(() => {
    makeSearch();
  }, [query, searchFields, filterFields, options, makeSearch]);

  const handleReload = () => {
    makeSearch();
  };

  const change = (value) => {
    const validValue = value != query && value !== undefined;
    if (validValue) {
      setQuery(value);
    }
  };

  const changeFilter = (value) => {
    if (!isEqual(filterFields, value)) {
      setFilter(value);
    }
  };

  const changeLimit = (newLimit) => {
    setOptions({
      ...options,
      limit: newLimit || DEFAULT_QUERY_OPTIONS.limit,
      page: DEFAULT_QUERY_OPTIONS.page,
    });
  };

  const changePage = (newPage) => {
    setOptions({ ...options, page: newPage || DEFAULT_QUERY_OPTIONS.page });
  };

  const changeSort = (newSort) => {
    setOptions({ ...options, sort: newSort });
  };

  return {
    reload: handleReload,
    query: {
      ...options,
      qs: query,
      result,
      error,
      isLoading,
    },
    pristine,
    change,
    changeFilter,
    changeLimit,
    changePage,
    changeSort,
  };
}

export default useSearchState;
