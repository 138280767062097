import { number, shape, string } from 'prop-types';

import { get } from 'lodash';

const MyPluginVM = (plugin = {}) => {
  return {
    description: get(plugin, 'description'),
    id: plugin.id,
    logoUrl: get(plugin, 'logo_url'),
    name: get(plugin, 'name'),
    termsUrl: get(plugin, 'developer.terms_of_use_url'),
    developerName: get(plugin, 'developer.name'),
  };
};

MyPluginVM.propTypes = shape({
  description: string.isRequired,
  id: number.isRequired,
  logoUrl: string.isRequired,
  name: string.isRequired,
  termsUrl: string.isRequired,
  developer_name: string.isRequired,
});

export default MyPluginVM;
