const placeholders = {
  image: {
    style: { width: 100, height: 60 },
    type: 'rect',
  },
  title: {
    rows: 1,
    style: { width: 38, height: 18 },
    type: 'text',
  },
  description: {
    style: { width: 150, height: 22 },
    type: 'text',
    rows: 2,
  },
  author: {
    type: 'text',
    rows: 1,
    style: { width: 130, height: 16 },
  },
  lastUpdate: {
    rows: 1,
    type: 'text',
    style: { width: 80, height: 15 },
  },
  button: {
    style: { width: 22, height: 16 },
    type: 'rect',
  },
};

export default placeholders;
