import React from 'react';
import { any, string, func } from 'prop-types';
import Base from 'react-bootstrap/Button';
import cx from 'classnames';

function Button({ className, onClick, value, ...rest }) {
  const handleClick = (event) => {
    event.stopPropagation();
    onClick(value, event);
  };

  return (
    <Base
      className={cx(
        className,
        'd-flex justify-content-center align-items-center px-2',
      )}
      onClick={handleClick}
      {...rest}
    />
  );
}

Button.propTypes = {
  className: string,
  onClick: func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  value: any,
};

Button.defaultProps = {
  value: undefined,
  className: undefined,
};

export default Button;
