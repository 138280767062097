const Texts = {
  formTitle: 'Upload Plugin Manifest',
  fileLabel: 'File',
  requiredError: (name) => `${name} is required`,
  submitLabel: 'Push',
  cancelLabel: 'Cancel',
  submittingLabel: 'Uploading...',
  guideMessage:
    'For more information about the manifest schema visit our docs.',
};

export default Texts;
