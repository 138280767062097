import React, { useContext } from 'react';
import { node, shape } from 'prop-types';
import { get } from 'lodash';
import ReactPlaceholder from 'react-placeholder';
import 'react-placeholder/lib/reactPlaceholder.css';

import Context from './Context';

function Placeholder({ children, placeholderProps, ...props }) {
  const { isLoading } = useContext(Context);
  return (
    <ReactPlaceholder
      showLoadingAnimation
      ready={!isLoading}
      {...placeholderProps}
      className={get(props, 'className')}
    >
      <div {...props}>{children}</div>
    </ReactPlaceholder>
  );
}

Placeholder.propTypes = {
  children: node.isRequired,
  // eslint-disable-next-line react/require-default-props
  placeholderProps: shape({}),
};

export default Placeholder;
