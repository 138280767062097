/* eslint-disable import/no-named-as-default */
import React from 'react';
import { hot } from 'react-hot-loader';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import PrivateRouteFactory from 'common/Routes/PrivateRoute';
import Catalog from 'Pages/Catalog';
import AppSelector from 'Pages/AppSelector';
import Http404Factory from 'Pages/404';
import Redirect from 'Pages/OAuth/Redirect';
import OAuthPermission from 'Pages/OAuth/Permission';

import routerMap from './RouterMap';
import './styles.scss';

const PrivateRoute = PrivateRouteFactory(routerMap.socialLogin.path);
const Http404 = Http404Factory(routerMap.catalog.path);

function Main() {
  return (
    <div className="App">
      <Router>
        <Switch>
          <Route
            exact
            path={routerMap.socialLogin.path}
            component={OAuthPermission}
          />
          <Route
            exact
            path={routerMap.oauthRedirect.path}
            component={Redirect}
          />
          <PrivateRoute
            exact
            path={routerMap.catalog.path}
            component={Catalog}
          />
          <PrivateRoute
            exact
            path={routerMap.appSelector.path}
            component={AppSelector}
          />
          <Http404 />
        </Switch>
      </Router>
    </div>
  );
}

export default hot(module)(Main);
