import React from 'react';
import { CardDeck, Col } from 'react-bootstrap';
import { Upload } from 'react-bootstrap-icons';
import { func } from 'prop-types';
import { get } from 'lodash';

import SearchMyPlugins from 'Services/Me/SearchPlugins';
import Button from 'common/Buttons/Button';
import FadeIn from 'common/Animations/FadeIn';
import SearchInput from 'common/Inputs/Search';
import DataSet from 'common/DataSet';
import NoResults from 'common/Messages/NoResults';
import useSearchState from 'utils/useSearchState';
import withServices from 'utils/withServices';

import LoadingSpinner from 'common/LoadingSpinner';
import MyPluginCard from './components/MyPluginCard';

export function MyPlugins({ goToUpload, goToPluginDetails, searchService }) {
  const search = useSearchState(
    (qs, handler) => {
      searchService('userId', qs, handler);
    },
    ['q'],
    undefined,
    {
      sort: [{ field: 'name', order: 'asc' }],
      limit: 12,
    },
  );

  const showNoResults =
    !search.query.result.total && !search.pristine && !search.query.isLoading;

  return (
    <div>
      <div className="header d-flex align-items-center justify-content-between">
        <SearchInput
          placeholder="Search My Plugins"
          name="myPluginSearch"
          onChange={get(search, 'change')}
          value={get(search, 'query.qs')}
        />
        <Button onClick={goToUpload} className="ml-2 py-2 px-3" size="md">
          <Upload />
        </Button>
      </div>
      <DataSet
        onPageChange={get(search, 'changePage')}
        onReload={get(search, 'reload')}
        total={get(search, 'query.result.total')}
        page={get(search, 'query.page')}
        limit={get(search, 'query.limit')}
        hide="limit"
        isFetching={get(search, 'query.isLoading')}
        hasError={false && get(search, 'query.error')}
      >
        {showNoResults && (
          <div className="mt-2">
            <NoResults />
          </div>
        )}
        {get(search, 'query.isLoading') ? (
          <LoadingSpinner />
        ) : (
          <CardDeck className="mt-2">
            {get(search, 'query.result.data').map((value) => (
              <Col
                key={value.id}
                className="p-0 py-2"
                xs="12"
                sm="6"
                md="4"
                lg="4"
              >
                <FadeIn delay={value}>
                  <MyPluginCard onClick={goToPluginDetails} plugin={value} />
                </FadeIn>
              </Col>
            ))}
          </CardDeck>
        )}
      </DataSet>
    </div>
  );
}

MyPlugins.propTypes = {
  goToPluginDetails: func.isRequired,
  goToUpload: func.isRequired,
  searchService: func.isRequired,
};

export default withServices({ searchService: SearchMyPlugins }, MyPlugins);
