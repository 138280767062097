/* eslint-disable import/no-named-as-default */
import React, { useState } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import Panel, { usePanelLayer } from 'common/Panel';
import ManifestUpload from 'Views/ManifestUpload';
import PluginDetails from 'Views/PluginDetails';
import MyPlugins from 'Views/MyPlugins';
import CatalogSideBar from 'Views/CatalogSideBar';
import './styles.scss';

const MAIN_PANEL = 'main_panel';
const UPLOAD_PANEL = 'upload';
const PLUGIN_DETAILS = 'plugin_details';

function Catalog() {
  const panelLayer = usePanelLayer(MAIN_PANEL);
  // const login = useSocialAuth();
  const [layoutMeta, setMeta] = useState({});

  const handleDetailsActive = (pluginId) => {
    setMeta({ ...layoutMeta, pluginId });
    panelLayer.bringUpfront(PLUGIN_DETAILS)();
  };

  return (
    <Container className="app-container bg-main">
      <Row xs={12}>
        <Col className="app-sidebar px-1 mt-1 py-1" xs={12} sm={4}>
          <CatalogSideBar
            onGoToDetails={handleDetailsActive}
            onInstalled={handleDetailsActive}
          />
        </Col>
        <Col className="app-content mt-1 pr-2 pl-1 py-1 d-flex" xs={12} sm={8}>
          <div id="content-layout">
            <Panel
              title="My Plugins"
              className="my-plugins-dimension"
              show={panelLayer.isActive(MAIN_PANEL)}
            >
              <MyPlugins
                goToPluginDetails={handleDetailsActive}
                goToUpload={panelLayer.bringUpfront(UPLOAD_PANEL)}
              />
            </Panel>
            <Panel
              title="Upload Manifest"
              className="manifest-dimension"
              onGoBack={panelLayer.bringUpfront(MAIN_PANEL)}
              show={panelLayer.isActive(UPLOAD_PANEL)}
            >
              <ManifestUpload
                onSubmitted={handleDetailsActive}
                onCancel={panelLayer.bringUpfront(MAIN_PANEL)}
              />
            </Panel>
            <Panel
              title="Plugin Details"
              onGoBack={panelLayer.bringUpfront(MAIN_PANEL)}
              show={panelLayer.isActive(PLUGIN_DETAILS)}
            >
              <PluginDetails
                pluginId={layoutMeta.pluginId}
                onGoBack={panelLayer.bringUpfront(MAIN_PANEL)}
              />
            </Panel>
          </div>
        </Col>
      </Row>
      <Row xs={12} sm={12} className="" />
    </Container>
  );
}

export default Catalog;
