import React, { useEffect, useCallback, useState } from 'react';
import InputGroup from 'react-bootstrap/InputGroup';
import Form from 'react-bootstrap/Form';
import { Search as SearchIcon } from 'react-bootstrap-icons';
import cx from 'classnames';
import { string, func } from 'prop-types';
import { debounce, get } from 'lodash';
import './styles.scss';

function Search({ className, onBlur, onChange, ...props }) {
  const [localValue, setLocal] = useState(get(props, 'value'));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceOnChange = useCallback(debounce(onChange, 500), []);

  useEffect(() => {
    debounceOnChange(localValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localValue]);

  const handleChange = (event) => {
    setLocal(event.target.value);
  };

  const handleBlur = (event) => {
    const handler = onBlur || onChange;
    handler(event.target.value);
  };

  return (
    <InputGroup className="box-shadow custom-search-input-group">
      <InputGroup.Prepend className="custom-search-input-prepend">
        <div className="px-2 d-flex align-items-center justify-content-center">
          <SearchIcon />
        </div>
      </InputGroup.Prepend>
      <Form.Control
        className={cx('custom-search-input', className)}
        type="text"
        {...props}
        value={localValue}
        onBlur={handleBlur}
        onChange={handleChange}
      />
    </InputGroup>
  );
}

Search.propTypes = {
  className: string,
  onBlur: func,
  onChange: func.isRequired,
};

Search.defaultProps = {
  className: null,
  onBlur: null,
};

export default Search;
