import React, { useMemo } from 'react';
import { node, number } from 'prop-types';
import { motion } from 'framer-motion';

function FadeIn({ children, delay, duration }) {
  const variants = useMemo(() => {
    const result = {
      hidden: { opacity: 0 },
      visible: {
        opacity: 1,
        transition: { duration, delay: delay * 0.05 },
      },
    };

    return result;
  }, [delay, duration]);

  return (
    <motion.div variants={variants} initial="hidden" animate="visible">
      {children}
    </motion.div>
  );
}

FadeIn.propTypes = {
  children: node.isRequired,
  delay: number,
  duration: number,
};

FadeIn.defaultProps = {
  delay: 0,
  duration: 0.5,
};

export default FadeIn;
