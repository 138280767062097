import React from 'react';
import { func } from 'prop-types';
import { Card } from 'react-bootstrap';
import useToViewModel from 'utils/useToViewModel';
import Button from 'common/Buttons/Button';

import PostPluginSelected from 'Services/Plugins/PostPluginSelected';
import withServices from 'utils/withServices';
import PluginSelectCardVM from './PluginSelectCardVM';

import './styles.scss';

function PluginSelectCard({ plugin, postPluginSelected }) {
  const vm = useToViewModel(plugin, PluginSelectCardVM);

  return (
    <Card className="box-shadow my-plugin-card mx-2">
      <div className="d-flex justify-content-end align-items-end">
        <div className="app-image" width="80" height="100" />
      </div>
      <Card.Body className="p-2">
        <Card.Title className="cx-content-title mb-1">{vm.name}</Card.Title>
        <Card.Text className="cx-content mb-1">{vm.description}</Card.Text>
        <div className="cx-content-sub p-0 mb-2">By {vm.developerName}</div>
        <div>
          <Button
            onClick={postPluginSelected}
            value={vm}
            variant="dark"
            className="cx-content-action plugin-card-btn-go p-1 text-white"
          >
            Use
          </Button>
        </div>
      </Card.Body>
    </Card>
  );
}

PluginSelectCard.propTypes = {
  plugin: PluginSelectCardVM.propTypes.isRequired,
  postPluginSelected: func.isRequired,
};

export default withServices(
  { postPluginSelected: PostPluginSelected },
  PluginSelectCard,
);
