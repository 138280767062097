import React from 'react';
import { func } from 'prop-types';
import ListGroup from 'react-bootstrap/ListGroup';
import { get } from 'lodash';
import cx from 'classnames';
import PluginsSearch from 'Services/Plugins/Search';
import InstallPlugin from 'Services/Plugins/Install';

import useSearchState from 'utils/useSearchState';
import { useRequest } from 'utils/Request';
import withServices from 'utils/withServices';
import FadeIn from 'common/Animations/FadeIn';
import SearchInput from 'common/Inputs/Search';
import NoResults from 'common/Messages/NoResults';
import PlaceholderProvider from 'common/Animations/Placeholder/Provider';
import PluginItem from './components/PluginItem';
import './styles.scss';

const placeholderData = [1, 2, 3, 4];
const noResultStyle = { height: '455px', marginTop: '8px' };

export function Catalog({
  onGoToDetails,
  onInstalled,
  searchService,
  installService,
}) {
  const search = useSearchState(
    (qs, handler) => {
      searchService(qs, handler);
    },
    ['q'],
    undefined,
    {
      sort: [{ field: 'name', order: 'asc' }],
      limit: 12,
    },
  );

  const install = useRequest(installService, {
    onSuccess: (response) => {
      onInstalled(response.data.id);
    },
    onError: () => {},
  });

  const dataSet = search.query.isLoading
    ? placeholderData
    : search.query.result.data;

  const showNoResults =
    !search.query.result.total && !search.pristine && !search.query.isLoading;

  return (
    <div className="plugin-catalog">
      <SearchInput
        onChange={get(search, 'change')}
        value={get(search, 'query.qs')}
        placeholder="Search for Plugins"
      />
      <div className="search-result">
        <PlaceholderProvider loading={search.query.isLoading}>
          <ListGroup
            variant="flush"
            className={cx(!showNoResults && 'catalog-list')}
          >
            {!get(search, 'query.error') &&
              !showNoResults &&
              dataSet.map((value) => (
                <FadeIn key={value.plugin_id} delay={value}>
                  <PluginItem
                    plugin={value}
                    onGoToDetails={onGoToDetails}
                    onInstall={install.start}
                  />
                </FadeIn>
              ))}
          </ListGroup>
          {showNoResults && (
            <div style={noResultStyle}>
              <NoResults />
            </div>
          )}
        </PlaceholderProvider>
      </div>
    </div>
  );
}

Catalog.propTypes = {
  installService: func.isRequired,
  onGoToDetails: func.isRequired,
  onInstalled: func.isRequired,
  searchService: func.isRequired,
};

export default withServices(
  { searchService: PluginsSearch, installService: InstallPlugin },
  Catalog,
);
