import http from 'utils/Http';

async function Details(pluginId, responseHandlers) {
  try {
    const { data: response } = await http.get({
      url: `api/v1/plugins/${pluginId}`,
    });

    const mapped = response;
    responseHandlers.onSuccess({
      data: mapped,
    });
  } catch (error) {
    responseHandlers.onError(error);
  }
}

export default Details;
