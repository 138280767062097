import { number, shape, string } from 'prop-types';

import { get } from 'lodash';

const PluginVM = (plugin = {}) => {
  return {
    description: plugin.description,
    id: plugin.id,
    name: plugin.name,
    developerName: get(plugin, 'developer.name'),
  };
};

PluginVM.propTypes = shape({
  description: string.isRequired,
  id: number.isRequired,
  name: string.isRequired,
  developerName: string.isRequired,
});

export default PluginVM;
