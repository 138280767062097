const placeholders = {
  image: {
    style: { width: 100, height: 60 },
    type: 'rect',
  },
  title: {
    rows: 1,
    style: { width: 80, height: 25 },
    type: 'text',
  },
  description: {
    style: { width: 338, height: 20 },
    type: 'text',
    rows: 1,
  },
  author: {
    type: 'text',
    rows: 1,
    style: { width: 130, height: 16 },
  },
  lastUpdate: {
    rows: 1,
    type: 'text',
    style: { width: 80, height: 15 },
  },
  button: {
    style: { width: 60, height: 24 },
    type: 'rect',
  },
  removeButton: {
    style: { width: 80, height: 24 },
    type: 'rect',
  },
};

export default placeholders;
