import React from 'react';
import { func, string } from 'prop-types';

import Texts from './texts';
import './styles.scss';

function HttpStatus({ getBackPath, status, title }) {
  return (
    <div className="http-container">
      <div className="center-container">
        <div className="d-flex justify-content-between align-items-center">
          <div className="mr-3 pr-3 status-code d-flex align-items-center">
            <h1>{status}</h1>
          </div>
          <div className="status-message">
            <h4 className="mt-1">{title}</h4>
            <a href={getBackPath} rel="noopener noreferrer">
              {Texts.backMessage}
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

HttpStatus.propTypes = {
  getBackPath: func.isRequired,
  status: string.isRequired,
  title: string.isRequired,
};

export default HttpStatus;
