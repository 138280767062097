import http from 'utils/Http';

async function InstallPlugin(pluginToInstall, responseHandlers) {
  try {
    const { data: response } = await http.post({
      url: 'api/v1/plugins/install',
      body: {
        id: pluginToInstall,
        type: 'JIVE',
      },
    });

    const mapped = response;
    responseHandlers.onSuccess({
      data: mapped.plugin,
    });
  } catch (error) {
    responseHandlers.onError(error);
  }
}

export default InstallPlugin;
