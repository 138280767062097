import { number, shape, string } from 'prop-types';

import { get } from 'lodash';

const PluginSelectCardVM = (plugin = {}) => {
  return {
    appUrl: get(plugin, 'app.content_url'),
    configUrl: get(plugin, 'app.config_url'),
    description: get(plugin, 'description'),
    id: plugin.id,
    logoUrl: get(plugin, 'logo_url'),
    name: get(plugin, 'name'),
    termsUrl: get(plugin, 'developer.terms_of_use_url'),
    developerName: get(plugin, 'developer.name')
  };
};

PluginSelectCardVM.propTypes = shape({
  appUrl: string.isRequired,
  configUrl: string.isRequired,
  description: string.isRequired,
  id: number.isRequired,
  logoUrl: string.isRequired,
  name: string.isRequired,
  termsUrl: string.isRequired,
  developer_name: string.isRequired,
});

export default PluginSelectCardVM;
