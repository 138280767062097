const defaultGlobalEnvs = {
  domain: process.env.REACT_APP_DOMAIN_AUSPP,
  env: process.env.REACT_APP_ENV,
};

class Config {
  constructor() {
    this.envs = defaultGlobalEnvs;
  }

  get = (key) => {
    return this.envs[key];
  };
}

export default new Config();
