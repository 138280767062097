const routerMap = {
  catalog: {
    path: '/catalog',
    getPath: () => '/catalog',
  },
  socialLogin: {
    path: '/oauth/login',
    getPath: () => '/oauth/login',
  },
  oauthRedirect: {
    path: '/oauth/redirect',
    getPath: () => '/oauth/redirect',
  },
  appSelector: {
    path: '/app-selector',
    getPath: () => '/app-selector',
  },
};

export default routerMap;
