import React from 'react';
import { string } from 'prop-types';

import LoadingSpinner from 'common/LoadingSpinner';

function PageSpinner({ message }) {
  return (
    <div className="http-container">
      <div className="center-container">
        <div className="d-flex justify-content-between align-items-center">
          <div className="status-message">
            <h4 className="mt-1">{message}</h4>
            <LoadingSpinner />
          </div>
        </div>
      </div>
    </div>
  );
}

PageSpinner.propTypes = {
  message: string,
};

PageSpinner.defaultProps = {
  message: 'Loading...',
};

export default PageSpinner;
