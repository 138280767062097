import Cookies from 'js-cookie';
import { get } from 'lodash';
import appConfig from 'Config';

export const SESSION_NAME = 'embedded_auspp_session';
export const SESSION_ACCESS = 'embedded_auspp_access';
export const SESSION_USER = 'embedded_auspp_session_user';
export const SESSION_PROVIDER = 'embedded_auspp_session_provider';
export const SESSION_APP_ACCESS = 'embedded_auspp_sesssion_app_access';
const COOKIE_DOMAIN = window.location.hostname;

const env = appConfig.get('env');
const BASE_COOKIE_CONFIG =
  env === 'qa' || env === 'prod'
    ? { sameSite: 'none', secure: true }
    : { sameSite: 'lax' };

class Session {
  /**
   * getDomainCookie
   * @param {string} name
   */
  getDomainCookie = (name) => {
    const value = Cookies.getJSON(name);
    return value || null;
  };

  /**
   * get session prop
   */
  get = (prop) => {
    const session = Cookies.getJSON(SESSION_NAME);
    return get(session, prop, null);
  };

  /**
   * save
   * @param {object} session
   */
  save = (session) => {
    const sessionCopy = { ...session };
    const secondsToExpiration = session.expires_in;
    const msToExpiration = (secondsToExpiration - 300) * 1000;

    sessionCopy.expires_at = Date.now() + msToExpiration;
    Cookies.set(SESSION_NAME, session, {
      ...BASE_COOKIE_CONFIG,
      domain: COOKIE_DOMAIN,
    });
  };

  /**
   * set access cookie for permissions
   * @param {object} session
   */
  setAccess = (access) => {
    Cookies.set(SESSION_ACCESS, access, {
      ...BASE_COOKIE_CONFIG,
      domain: COOKIE_DOMAIN,
    });
  };

  /**
   * set access cookie for permissions
   * @param {object} session
   */
  setProvider = (provider) => {
    Cookies.set(SESSION_PROVIDER, provider, {
      ...BASE_COOKIE_CONFIG,
      domain: COOKIE_DOMAIN,
    });
  };

  setProviderGateway = (entryPath) => {
    Cookies.set(
      SESSION_APP_ACCESS,
      {
        entryPath,
      },
      {
        ...BASE_COOKIE_CONFIG,
        domain: COOKIE_DOMAIN,
      },
    );
  };

  /**
   * set cookie for user information
   * @param {object} session
   */
  setUser = (user) => {
    Cookies.set(
      SESSION_USER,
      { user },
      { ...BASE_COOKIE_CONFIG, domain: COOKIE_DOMAIN },
    );
  };

  getUsername = () => {
    const username = get(
      this.getDomainCookie(SESSION_USER),
      'user.username',
      '',
    );
    return username;
  };

  getUser = () => {
    const user = get(this.getDomainCookie(SESSION_USER), 'user', {});
    return user;
  };

  removeCookie = () => {
    Object.keys(Cookies.get()).forEach((cookieName) => {
      Cookies.remove(cookieName, { domain: COOKIE_DOMAIN });
    });
  };

  getTokenProp = (prop) => {
    const token = this.get('token');
    if (!token) return undefined;
    // as token consists of 3 components in the following order; header, body and secret
    const tokenBody = token.split('.')[1];

    if (!tokenBody) return undefined;

    const decoded = Buffer.from(tokenBody, 'base64').toString('ascii');
    const parsedToken = JSON.parse(decoded);
    return parsedToken[prop];
  };

  shouldRefreshToken = () => {
    const expiresAt = this.getTokenProp('exp');
    const shouldRefresh = !expiresAt || Date.now() >= expiresAt * 1000;
    return shouldRefresh;
  };

  /**
   * clears all user related data and redirects to login
   */
  clear = () => {
    this.removeCookie();
    const redirectUrl = '/';
    window.location = redirectUrl;
  };
}

const session = new Session();

export default session;
