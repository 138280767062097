import React, { useEffect } from 'react';
import { get, isEmpty } from 'lodash';
import { useHistory } from 'react-router-dom';

import http from 'utils/Http';
import session, { SESSION_PROVIDER, SESSION_APP_ACCESS } from 'utils/Session';
import { useRequest } from 'utils/Request';
import useQueryString from 'utils/useQueryString';
import HttpStatus from 'common/HttpStatus';
import PageSpinner from 'common/PageSpinner';
import SocialLogin from 'Services/Auth/SocialLogin';

function Redirect() {
  const qs = useQueryString();
  const history = useHistory();
  const loginRequest = useRequest(SocialLogin, {
    onSuccess: (response) => {
      session.save(get(response, 'token', {}));
      session.setUser(get(response, 'profile', {}));
      http.setToken(get(response, 'token.token', ''));
      http.registerLoginInterceptors();
      setTimeout(() => {
        const { entryPath = '/' } =
          session.getDomainCookie(SESSION_APP_ACCESS) || {};
        history.push(`/${entryPath}`);
      }, 0);
    },
    onError: () => {},
  });

  useEffect(() => {
    const authCode = qs.get('code') || null;
    const hasUser = session.getUsername();
    const provider = session.getDomainCookie(SESSION_PROVIDER);
    if (!hasUser) {
      loginRequest.start({ authCode, provider: provider.name });
    } else {
      const { entryPath = '/' } =
        session.getDomainCookie(SESSION_APP_ACCESS) || {};
      history.push(`/${entryPath}`);
    }
  }, []);

  return (
    <div>
      {loginRequest.inProcess && <PageSpinner />}
      {!isEmpty(loginRequest.error) && (
        <HttpStatus status="401" title="Could not authenticate" />
      )}
    </div>
  );
}

export default Redirect;
