import React from 'react';

function NoResults() {
  return (
    <div className="d-flex justify-content-center bg-secondary p-3 text-dark font-weight-bold">
      No Results
    </div>
  );
}

export default NoResults;
