import React from 'react';
import HttpStatus from 'common/HttpStatus';
import Texts from './texts';

function Http404HoC(fallbackPath) {
  function Http404() {
    return (
      <HttpStatus getBackPath={fallbackPath} status="404" title={Texts.title} />
    );
  }

  return Http404;
}

export default Http404HoC;
