import React, { useState } from 'react';
import { Card } from 'react-bootstrap';
import cx from 'classnames';
import { ArrowLeft as GoBackIcon } from 'react-bootstrap-icons';

import { bool, func, node, string } from 'prop-types';
import Button from 'common/Buttons/Button';
import './styles.scss';
import FadeIn from 'common/Animations/FadeIn';

export const usePanelLayer = (initial) => {
  const [active, setActive] = useState(initial);

  const bringUpfront = (value) => () => setActive(value);
  const isActive = (value) => value === active;

  return {
    bringUpfront,
    isActive,
  };
};

function Panel({ children, onGoBack, show, title, className }) {
  if (!show) return false;
  return (
    <FadeIn duration={0.75}>
      <Card className={cx(className, 'bg-transparent panel-wrapper')}>
        <div className="px-3 py-2 panel-header">
          <div className="d-flex justify-content-between align-items-center">
            <div className="d-flex justify-content-start align-items-center">
              {onGoBack && (
                <Button
                  onClick={onGoBack}
                  variant="secondary"
                  className="d-flex align-items-center mr-2 go-back"
                >
                  <GoBackIcon />
                </Button>
              )}
              <div className="cx-content-title">{title}</div>
            </div>
          </div>
        </div>
        <Card.Body className="panel-card-content">{children}</Card.Body>
      </Card>
    </FadeIn>
  );
}

Panel.propTypes = {
  children: node.isRequired,
  className: string,
  onGoBack: func,
  show: bool,
  title: string.isRequired,
};

Panel.defaultProps = {
  className: null,
  show: false,
  onGoBack: null,
};

export default Panel;
