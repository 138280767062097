import http from 'utils/Http';

const buildUrl = (suffix) => `api/v1/plugins${suffix ? `${suffix}` : ''}`;

async function SearchMyPlugins(query, responseHandlers) {
  try {
    const { data: response } = await http.get({
      url: buildUrl(query),
    });

    const mapped = response.data;

    responseHandlers.onSuccess({
      data: mapped,
      lastPage: response.lastPage,
      page: response.page,
      perPage: response.perPage,
      total: response.total,
      count: response.count,
    });
  } catch (error) {
    responseHandlers.onError(error);
  }
}

export default SearchMyPlugins;
