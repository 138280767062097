import React from 'react';
import { func } from 'prop-types';
import { Card } from 'react-bootstrap';
import { Gear } from 'react-bootstrap-icons';
import useToViewModel from 'utils/useToViewModel';
import Button from 'common/Buttons/Button';
import MyPluginVM from './MyPluginVM';

import './styles.scss';

function MyPluginCard({ plugin, onClick }) {
  const vm = useToViewModel(plugin, MyPluginVM);
  return (
    <Card className="box-shadow my-plugin-card">
      <div className="d-flex justify-content-end align-items-end">
        <div className="position-absolute p-1 pr-2">
          <Button
            onClick={onClick}
            variant="dark"
            className="cx-content-action plugin-card-btn-go p-1 text-white"
          >
            <Gear />
          </Button>
        </div>
        <div className="app-image px-1" width="80" height="100">
          <img width="80" height="60" src={vm.logoUrl} />
        </div>
      </div>
      <Card.Body className="p-2">
        <Card.Title className="cx-content-title mb-1">{vm.name}</Card.Title>
        <Card.Text className="cx-content mb-1">{vm.description}</Card.Text>
        <div className="cx-content-sub p-0 mb-2">By {vm.developerName}</div>
      </Card.Body>
    </Card>
  );
}

MyPluginCard.propTypes = {
  onClick: func.isRequired,
  plugin: MyPluginVM.propTypes.isRequired,
};

export default MyPluginCard;
