import http from 'utils/Http';

async function LoadSocialProvider(provider, responseHandlers) {
  try {
    const { data: response } = await http.get({
      url: `oauth/provider_request?provider=${provider}`,
    });
    const mapped = response;
    responseHandlers.onSuccess({
      data: mapped,
    });
  } catch (error) {
    responseHandlers.onError(error);
  }
}

export default LoadSocialProvider;
