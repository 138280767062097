import http from 'utils/Http';

const SocialLogin = async ({ authCode, provider }, responseHandlers) => {
  try {
    const response = await http.post({
      url: `oauth/auth`,
      body: {
        authCode,
        provider,
      },
    });
    responseHandlers.onSuccess(response.data);
  } catch (error) {
    if (responseHandlers.onError) {
      responseHandlers.onError(error);
    }
  }
};

export default SocialLogin;
