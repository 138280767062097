import React from 'react';
import { func, string } from 'prop-types';
import { Route, Redirect } from 'react-router-dom';
import session from 'utils/Session';
// import routerMap from 'app/RouterMap';

function PrivateRouteHoC(loginPath) {
  function PrivateRoute({ component: Component, requiredRole, ...rest }) {
    const token = session.get('token');
    const isAuthenticated = token;

    const getRoute = (props) => {
      if (!isAuthenticated) {
        return <Redirect to={loginPath} />;
      }

      return <Component {...props} />;
    };

    return <Route {...rest} render={(props) => getRoute(props)} />;
  }

  PrivateRoute.propTypes = {
    component: func.isRequired,
    requiredRole: string,
  };

  PrivateRoute.defaultProps = {
    requiredRole: undefined,
  };

  return PrivateRoute;
}
export default PrivateRouteHoC;
