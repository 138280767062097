import React, { useState, useEffect } from 'react';
import { bool, node } from 'prop-types';
import Context from './Context';

function Provider({ initial, loading, children }) {
  const [isLoading, setIsLoading] = useState(initial);
  useEffect(() => {
    setIsLoading(loading);
  }, [loading]);

  return (
    <Context.Provider value={{ isLoading, setIsLoading }}>
      {children}
    </Context.Provider>
  );
}

Provider.propTypes = {
  children: node.isRequired,
  initial: bool,
  loading: bool,
};

Provider.defaultProps = {
  initial: false,
  loading: false,
};

export default Provider;
